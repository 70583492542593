import styled from "styled-components";
import IconButton from "@mui/material/IconButton";

const HelpIcon = styled(IconButton)`
  & svg {
    color: ${({ theme }) => theme.helpIconColor};
  }
`;

export { HelpIcon };
