import React from "react";
import { ResponsivePie } from "@nivo/pie";

export const PieChartTotalWords = ({ data }) => {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
      innerRadius={0.5}
      padAngle={1}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      theme={{
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#333333",
            fontSize: 14,
          },
        },
      }}
      radialLabelsTextColor="#EC008C"
      radialLabelsLinkColor="#EC008C"
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 4]],
      }}
    />
  );
};
