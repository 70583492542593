import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  .app {
    background-color: ${({ theme }) => theme.backgroundColor};
    background-image: url(${({ theme }) => theme.backgroundUrl});
    background-repeat: ${({ theme }) => theme.backgroundRepeat};
    background-position: ${({ theme }) => theme.backgroundPosition};
    background-size: ${({ theme }) => theme.backgroundSize};
    color: ${({ theme }) => theme.color};
    transition: all 0.20s linear;
  }
  
  a { color: ${({ theme }) => theme.linkColor}; }
  
  header {
    background-color: ${({ theme }) => theme.headerBackgroundColor};
    background-image: url(${({ theme }) => theme.headerBackgroundUrl});
    background-repeat: ${({ theme }) => theme.headerBackgroundRepeat};
    background-position: ${({ theme }) => theme.headerBackgroundPosition};
    background-size: ${({ theme }) => theme.headerBackgroundSize};
    color: ${({ theme }) => theme.headerTextColor};
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    border-bottom: 1px solid ${({ theme }) => theme.headerBorderColor};
    position: sticky;
    top: 0;
    z-index: 1;

    /*
    background: rgb(43,154,118);
    background: linear-gradient(180deg, rgba(43,154,118,1) 0%, rgba(43,154,118,1) 0%, rgba(34,45,95,1) 100%);
    */
  }

  header a { color: ${({ theme }) => theme.headerTextColor}; }
  header a:hover { color: ${({ theme }) => theme.headerHightlightColor}; }

  footer {
    background-color: ${({ theme }) => theme.footerBackgroundColor};
    background-image: url(${({ theme }) => theme.footerBackgroundUrl});
    background-repeat: ${({ theme }) => theme.footerBackgroundRepeat};
    background-position: ${({ theme }) => theme.footerBackgroundPosition};
    background-size: ${({ theme }) => theme.footerBackgroundSize};
    color: ${({ theme }) => theme.footerColor};
    box-shadow: 0 -2px 8px rgb(0 0 0 / 20%);
    border-top: 1px solid ${({ theme }) => theme.footerBorderColor};
  }

  footer a { color: ${({ theme }) => theme.footerColor}; }

  .progress {
    background: ${({ theme }) => theme.progressBackgroundColor};
    color: ${({ theme }) => theme.progressTextColor};
    border: ${({ theme }) => theme.progressBorder};
  }

  .search-results ul li:hover { background-color: ${({ theme }) =>
    theme.searchResultsHoverBGColor}; }

  .stats .song-divider { background-color: ${({ theme }) =>
    theme.songStatUnderlineColor}; }
    
  .stats .song-divider { background-color: ${({ theme }) =>
    theme.songDividerColor}; }

  .analyze .misc-stats .underline { border-bottom: 1px solid ${({ theme }) =>
    theme.songStatUnderlineColor}; }

  .header::before { background: linear-gradient(to right, ${({ theme }) =>
    theme.backgroundColor}, ${({ theme }) =>
  theme.dividerColor}); margin-right: 20px; }

  .header::after { background: linear-gradient(to left, ${({ theme }) =>
    theme.backgroundColor}, ${({ theme }) =>
  theme.dividerColor}); margin-left: 20px; } 

  footer .ls-logo
`;
