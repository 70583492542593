import styled from "styled-components";

const ToggleContainer = styled.button`
  background: ${({ theme }) => theme.toggleBackground};
  border: 1px solid ${({ theme }) => theme.toggleBorder};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  padding: 5px;
  position: relative;

  &:active,
  &:hover {
    background-color: ${({ theme }) => theme.toggleBackgroundHover};
  }

  img {
    height: auto;
    width: 20px;
    transition: all 0.2s linear;

    // sun icon
    &:first-child {
      position: relative;
      transform: ${({ isLight }) =>
        isLight ? "translateY(0)" : "translateY(50px)"};
    }

    // moon icon
    &:nth-child(2) {
      position: absolute;
      transform: ${({ isLight }) =>
        isLight ? "translateY(-50px)" : "translateY(0)"};
    }
  }
`;

export { ToggleContainer };
