import React from "react";
import { ResponsiveBar } from "@nivo/bar";

export const BarChartHomePage = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["number"]}
      indexBy="text"
      margin={{ top: 50, right: 40, bottom: 50, left: 40 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={({ id, data }) => data[`${id}-color`]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: "#EC008C",
              fontSize: 18,
              fontFamily: "Audiowide",
            },
          },
        },
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        fontSize: "40px",
      }}
      axisLeft={null}
      enableGridY={false}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 4.0]],
      }}
      isInteractive={false}
      enableLabel={false}
    />
  );
};
