import styled from "styled-components";
import TextField from "@mui/material/TextField";

const SearchTextField = styled(TextField)`
  & legend {
    font-size: 0.9em;
  }
  & label {
    color: ${({ theme }) => theme.searchTextColor};
    font-size: 1.2em;
  }
  & label.Mui-focused {
    color: ${({ theme }) => theme.searchTextColor};
  }
  & input {
    color: ${({ theme }) => theme.searchTextColor};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ theme }) => theme.searchBorderColor};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.searchActiveBorderColor};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.searchActiveBorderColor};
    }
  }
  & .MuiFormHelperText-root {
    color: ${({ theme }) => theme.searchActiveBorderColor};
  }
  & svg {
    color: ${({ theme }) => theme.searchIconColor};
  }
`;

export { SearchTextField };
