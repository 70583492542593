import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SearchTextField } from "./SearchTextField.styled";

const getTermsFromUrl = (url) => {
  let terms = "";

  if (url.includes("/search/")) {
    let url_array = url.split("/");

    if (url_array.length > 0) {
      terms = url_array[4];
    }
  }

  return decodeURIComponent(terms);
};

const Search = () => {
  // To be able to redirect to /search/<terms>
  const navigate = useNavigate();

  // Get the search terms from the url parameter
  let terms = window.location.href.split("?")[0];

  // Make sure terms is a string
  if (typeof terms == "undefined") {
    terms = "";
  }

  // Set all the state properties
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerms, setTerms] = useState("");

  // Runs on change of terms, which is tied to the url
  useEffect(() => {
    // Terms changed, whether it was back or forward in the browser.
    setTerms(getTermsFromUrl(terms).replaceAll("-", " "));
  }, [terms]);

  // Handles a manual search (button click or enter)
  const search = (e) => {
    // prevent default since this will be called when they either click the search button or press enter on the search box
    e.preventDefault();

    // Only perform a redirect to the search page if they are searching for something
    if (searchTerms.trim() !== "") {
      // Redirect to the search url so the search will be performed on a url that will be linkable
      navigate(
        "/search/" + encodeURIComponent(searchTerms.replaceAll(" ", "-"))
      );
    } else {
      setErrorMessage("Enter a search term");
    }
  };

  const handleChange = (event) => {
    setTerms(event.target.value);

    if (event.target.value.trim() !== "") {
      setErrorMessage("");
    }
  };

  return (
    <div className="search">
      <form onSubmit={search} autoComplete="off">
        <div className="search-field">
          <SearchTextField
            fullWidth
            size="small"
            value={searchTerms}
            onChange={handleChange}
            id="search_terms"
            label="Search Albums"
            variant="outlined"
            helperText={errorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={search} aria-label="Search Albums">
                    <SearchIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Search;
