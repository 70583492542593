import React from "react";

export default function Logo({ className, showText }) {
  return (
    <div className={className}>
      <div className="ls-logo">
        <svg width="100%" viewBox="0 0 70 30">
          <rect width="10" height="20" y="10" />
          <rect width="10" height="10" x="14" y="20" />
          <rect width="10" height="30" x="28" />
          <rect width="10" height="30" x="42" y="0" />
          <rect width="10" height="15" x="56" y="15" />
        </svg>
      </div>
      {showText ? <div className="ls-text">LyricStat.com</div> : null}
    </div>
  );
}
