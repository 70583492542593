import React from 'react';
import './App.css';
import Header from './components/header'
import Footer from './components/footer'
import HomePage from './components/HomePage'
import SearchResults from './components/searchResults'
import Analyzer from './components/analyzer'
import PageNotFound from './components/PageNotFound'
import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './components/global';
import { useTheme } from './components/useTheme';

const AppRoutes = () =>
  useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/search/", element: <Navigate to="/" replace /> },
    { path: "/search/:terms", element: <SearchResults /> },
    { path: "/search/:terms/:page", element: <SearchResults /> },
    { path: "/analyze/", element: <Navigate to="/" replace /> },
    { path: "/analyze/:artist/:album", element: <Analyzer /> },
    { path: "*", element: <PageNotFound /> }
]);

const App = () => {
  const [theme, toggleTheme, componentMounted] = useTheme();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />
  };

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <div className="app">
        <div className="site">
          <BrowserRouter>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <AppRoutes/>
            <Footer />
          </BrowserRouter>
        </div>
      </div>
    </ThemeProvider>);
}

export default App;
