import React, { useState, useEffect } from "react";
import Progress from "./progress";
import { useParams, useNavigate } from "react-router-dom";
import { isInt } from "../helpers";
import { PieChartTotalWords } from "./charts/PieChartTotalWords";
import { BarChartWords } from "./charts/BarChartWords";
import { BarChartSyllabic } from "./charts/BarChartSyllabic";
import { BarChartWordLength } from "./charts/BarChartWordLength";
import { BarChartDifficultWords } from "./charts/BarChartDifficultWords";
import { BarChartOffensiveWords } from "./charts/BarChartOffensiveWords";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { HelpIcon } from "./HelpIcon.styled";
import Collapse from "@mui/material/Collapse";

const Analyzer = () => {
  // To be able to redirect to /search/<terms>
  const navigate = useNavigate();

  // Get the artist and album from the url parameters
  let { artist, album } = useParams();

  // Make sure artist and album are strings
  if (typeof artist == "undefined") artist = "";
  if (typeof album == "undefined") album = "";

  const [errorMessage, setErrorMessage] = useState("");
  const [showProgress, setShowProgress] = useState(true);
  const [albumData, setAlbumData] = useState({});
  const [progress, setProgress] = useState({
    message: "Analyzing...",
    percent: -1,
  });
  const [css, setCSS] = useState("");
  const [albumTotalWordData, setAlbumTotalWordData] = useState([]);
  const [songUniqueWordData, setSongUniqueWordData] = useState([]);
  const [songSyllabicWordData, setSongSyllabicWordData] = useState([]);
  const [songLengthWordData, setSongLengthWordData] = useState([]);
  const [songDifficultWordData, setSongDifficultWordData] = useState([]);
  const [songOffensiveWordData, setSongOffensiveWordData] = useState([]);

  const [totalWordsInfoShown, setTotalWordsInfoShown] = useState(false);
  const [uniqueWordsInfoShown, setUniqueWordsInfoShown] = useState(false);
  const [syllableWordsInfoShown, setSyllableWordsInfoShown] = useState(false);
  const [longWordsInfoShown, setLongWordsInfoShown] = useState(false);
  const [difficultWordsInfoShown, setDifficultWordsInfoShown] = useState(false);
  const [offensiveWordsInfoShown, setOffensiveWordsInfoShown] = useState(false);

  const handleTotalWordsInfoClicked = () => {
    setTotalWordsInfoShown((prev) => !prev);
  };

  const handleUniqueWordsInfoClicked = () => {
    setUniqueWordsInfoShown((prev) => !prev);
  };

  const handleSyllableWordsInfoClicked = () => {
    setSyllableWordsInfoShown((prev) => !prev);
  };

  const handleLongWordsInfoClicked = () => {
    setLongWordsInfoShown((prev) => !prev);
  };

  const handleDifficultWordsInfoClicked = () => {
    setDifficultWordsInfoShown((prev) => !prev);
  };

  const handleOffensiveWordsInfoClicked = () => {
    setOffensiveWordsInfoShown((prev) => !prev);
  };

  const getAlbum = async (artist, album) => {
    console.log(artist, album);
    // Create the request
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "artist=" + artist + "&title=" + album,
    };

    // Get the album info.
    const data = await fetch(
      process.env.REACT_APP_BACKEND_ROOT + "/api/get-album",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          setShowProgress(false);
          setErrorMessage(
            "Well, this is embarassing. Album data isn't available right now. Check back later."
          );
          return {};
        }
        return response;
      })
      .then((response) => response.json())
      .catch((error) => {
        // Hide the progress info
        setShowProgress(false);

        setErrorMessage(
          "Oh no! Something happened when fetching the album data. Please try again later."
        );
        console.log("albumDataError", error);
        return {};
      });

    return data;
  };

  const getLyrics = async (artist, title, id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "artist=" + artist + "&title=" + title + "&id=" + id,
    };

    const lyricsData = await fetch(
      process.env.REACT_APP_BACKEND_ROOT + "/api/get-lyrics",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          setProgress((prev) => ({
            ...prev,
            message: "Couldn't get lyrics for " + title,
          }));
        }
        return response;
      })
      .then((resp) => resp.json())
      .catch((error) => {
        setProgress((prev) => ({
          ...prev,
          message: "Couldn't get lyrics for " + title,
        }));
        console.log("lyricsDataError", error);
      });

    return lyricsData;
  };

  const runAnalyze = async () => {
    // Get the album info.
    let albumData = await getAlbum(artist, album);

    if (albumData.title) {
      document.title =
        "Analysis for " + albumData.title + " by " + albumData.artist;
    }

    if (albumData.dominantColor) {
      setCSS(
        ".divider-color { background-color: " + albumData.dominantColor + "; }"
      );
    }

    // Set the results in the state
    setAlbumData(albumData);

    // Now, we need to get all of the lyrics
    setProgress((prev) => ({
      ...prev,
      message: "Getting lyrics...",
      percent: 0,
    }));

    let got_lyrics = false;

    // Loop through each song and get the lyrics
    if (albumData.songs) {
      for (var i = 0; i < albumData.songs.length; i++) {
        let title = albumData.songs[i].title;

        // Update the progress
        setProgress((prev) => ({
          ...prev,
          message: "Fetching/analyzing lyrics for",
          song: title + "...",
        }));

        // Only get the lyrics if the lyrics are currently blank
        if (!albumData.songs[i].has_lyrics) {
          // Get the lyrics
          await getLyrics(
            albumData.songs[i].artist,
            albumData.songs[i].title,
            albumData.songs[i].id
          );
          got_lyrics = true;
        }

        const progress = ((i + 1) / albumData.songs.length) * 100;

        // Update the status
        setProgress((prev) => ({
          ...prev,
          percent: progress,
        }));
      }

      // Show 100% before closing the progress
      setProgress((prev) => ({
        ...prev,
        message: "Got lyrics",
        song: "",
        percent: 100,
      }));

      // If any lyrics were gotten, we need to refresh the album data so the stats will appear
      if (got_lyrics) {
        setProgress((prev) => ({
          ...prev,
          message: "Analyzing lyrics...",
          song: "",
          percent: 100,
        }));

        albumData = await getAlbum(artist, album);
      }

      // Set the results in the state
      setAlbumData(albumData);

      setShowProgress(false);

      // Prepare the data for the charts

      // Set the album total word data for the chart
      const word_data = [];
      const unique_word_data = [];
      const syllabic_word_data = [];
      const length_word_data = [];
      const difficult_word_data = [];
      const offensive_word_data = [];

      albumData.songs.map((track, idx) => {
        word_data.push({
          id: track.title,
          label: track.title,
          value: track.totalWordCount,
        });

        unique_word_data.push({
          song: track.title,
          "Non-unique": track.totalWordCount - track.uniqueWordCount,
          "Non-unique-color": "#62CDBB",
          Unique: track.uniqueWordCount,
          "Unique-color": "#F1E15B",
        });

        syllabic_word_data.push({
          song: track.title,
          Monosyllabic: track.monosyllabicWordCount,
          "Monosyllabic-color": "#62CDBB",
          Polysyllabic: track.polysyllabicWordCount,
          "Polysyllabic-color": "#F1E15B",
        });

        length_word_data.push({
          song: track.title,
          Short: track.shortWordCount,
          "Short-color": "#62CDBB",
          Long: track.longWordCount,
          "Long-color": "#F1E15B",
          Other:
            track.totalWordCount - track.shortWordCount - track.longWordCount,
          "Other-color": "#F47560",
        });

        difficult_word_data.push({
          song: track.title,
          Difficult: track.difficultWordCount,
          "Difficult-color": "#F1E15B",
          Other: track.totalWordCount - track.difficultWordCount,
          "Other-color": "#62CDBB",
        });

        offensive_word_data.push({
          song: track.title,
          Offensive: track.offensiveWordCount,
          "Offensive-color": "#F1E15B",
          Other: track.totalWordCount - track.offensiveWordCount,
          "Other-color": "#62CDBB",
        });

        return 0;
      });

      setAlbumTotalWordData(word_data);
      setSongUniqueWordData(unique_word_data);
      setSongSyllabicWordData(syllabic_word_data);
      setSongLengthWordData(length_word_data);
      setSongDifficultWordData(difficult_word_data);
      setSongOffensiveWordData(offensive_word_data);

      /*
      setProgress((prev) => ({
        ...prev,
        message: "Analyzing...",
        percent: -1,
      }));
      */
    }

    return 0;
  };

  useEffect(() => {
    // Update the progress
    setProgress((prev) => ({
      ...prev,
      message: "Getting album data...",
      percent: -1,
    }));

    runAnalyze();
  }, []);

  return (
    <div className="analyze">
      <style>{css}</style>
      {showProgress ? <Progress progress={progress} /> : null}

      {errorMessage !== "" ? (
        <div className="error">
          <span>{errorMessage}</span>
        </div>
      ) : null}

      {JSON.stringify(albumData) !== "{}" ? (
        <>
          <div className="album">
            <div className="cover">
              <div className="art">
                <img src={albumData.coverArtUrl} alt={albumData.title} />
              </div>
              {albumData.tags != null ? (
                <ul className="tags">
                  {albumData.tags.split("|").map((tag, idx) => {
                    return tag != "" ? (
                      <li key={idx}>
                        <a href={"/search/tag:" + tag}>{tag}</a>
                      </li>
                    ) : null;
                  })}
                </ul>
              ) : null}
            </div>

            <div className="details">
              <h2>{albumData.title}</h2>
              {albumData.artist ? (
                <div className="artist">{albumData.artist}</div>
              ) : null}
              {albumData.year ? (
                <div className="year">{albumData.year}</div>
              ) : null}

              {JSON.stringify(albumData.songs) !== "{}" &&
              albumData.songs.length > 0 ? (
                <>
                  <h3>Track Listing</h3>
                  <ul className="tracks">
                    {albumData.songs.map((track, idx) => {
                      return (
                        <li key={idx}>
                          {track.trackNumber
                            ? track.trackNumber + "."
                            : "[BONUS]"}
                          &nbsp;
                          {track.title}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : JSON.stringify(albumData.songs) !== "{}" ? (
                <div class="tracks-wait">Tracks loading...</div>
              ) : null}
            </div>
          </div>

          {albumTotalWordData.length > 0 && songUniqueWordData.length > 0 ? (
            <div className="stats">
              <div className="song-divider"></div>

              <h2 className="header">
                Total Words per Song
                <HelpIcon
                  onClick={handleTotalWordsInfoClicked}
                  aria-label="Show description"
                >
                  <HelpOutlineIcon fontSize="small" />
                </HelpIcon>
              </h2>

              <Collapse in={totalWordsInfoShown} className="info">
                Total words means all words, including stop words like [a, the,
                is, etc.]
              </Collapse>

              <div className="chart">
                <PieChartTotalWords data={albumTotalWordData} />
              </div>

              <div className="song-divider"></div>
              <h2 className="header">
                Total vs Unique Words per Song{" "}
                <HelpIcon
                  onClick={handleUniqueWordsInfoClicked}
                  aria-label="Show description"
                >
                  <HelpOutlineIcon fontSize="small" />
                </HelpIcon>
              </h2>

              <Collapse in={uniqueWordsInfoShown} className="info">
                Total words means all words, including stop words like [a, the,
                is, etc.] Unique words means the count of each unique word only
                once, even if they appear multiple times. This gives an idea of
                how varied the vocabulary is for that song, and for the album as
                a whole.
              </Collapse>

              <div className="chart">
                <BarChartWords data={songUniqueWordData} />
              </div>

              <div className="song-divider"></div>
              <h2 className="header">
                <div>
                  Monosyllabic vs Polysyllabic Words per Song
                  <HelpIcon
                    onClick={handleSyllableWordsInfoClicked}
                    aria-label="Show description"
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </HelpIcon>
                </div>
              </h2>

              <Collapse in={syllableWordsInfoShown} className="info">
                Monosyllabic words are words with only one syllable.
                Polysyllabic words three or more syllables. This is difficult to
                get accurate, because today's algorithms to detect syllables are
                not always 100% correct, but this gives an idea of how varied
                the use of more complex words are for these songs and this
                album.
              </Collapse>

              <div className="chart">
                <BarChartSyllabic data={songSyllabicWordData} />
              </div>

              <div className="song-divider"></div>
              <h2 className="header">
                Short vs Long Words per Song
                <HelpIcon
                  onClick={handleLongWordsInfoClicked}
                  aria-label="Show description"
                >
                  <HelpOutlineIcon fontSize="small" />
                </HelpIcon>
              </h2>

              <Collapse in={longWordsInfoShown} className="info">
                Long words are defined as anything with 7 or more characters.
                Short words are 3 or less, and anything else is between 4 and 6
                characters. This gives an idea of how much word lengths vary in
                each song and the album.
              </Collapse>

              <div className="chart">
                <BarChartWordLength data={songLengthWordData} />
              </div>

              <div className="song-divider"></div>
              <h2 className="header">
                Difficult Words per Song
                <HelpIcon
                  onClick={handleDifficultWordsInfoClicked}
                  aria-label="Show description"
                >
                  <HelpOutlineIcon fontSize="small" />
                </HelpIcon>
              </h2>

              <Collapse in={difficultWordsInfoShown} className="info">
                Difficult words can mean different things to different people.
                But, here, they are defined as a word with at least 2 syllables,
                is not a stop word [a, the, is, etc.], and is not in a
                pre-defined list of commmon English words. This gives an idea of
                how advanced the vocabulary of the song and album is.
              </Collapse>

              <div className="chart">
                <BarChartDifficultWords data={songDifficultWordData} />
              </div>

              <div className="song-divider"></div>
              <h2 className="header">
                Offensive Words per Song
                <HelpIcon
                  onClick={handleOffensiveWordsInfoClicked}
                  aria-label="Show description"
                >
                  <HelpOutlineIcon fontSize="small" />
                </HelpIcon>
              </h2>

              <Collapse in={offensiveWordsInfoShown} className="info">
                Stats on words that have been tagged as offensive. This gives an
                idea of how offensive the song and album is. Offensive words are
                shown below under each song word stats.
              </Collapse>

              <div className="chart">
                <BarChartOffensiveWords data={songOffensiveWordData} />
              </div>

              <div className="song-divider"></div>

              <div className="misc-stats">
                {albumData.songs.map((track, idx) => {
                  return (
                    <div className="song" key={idx}>
                      <h2 className="header">{track.title}</h2>

                      <table>
                        <tbody>
                          <tr>
                            <td className="caption">Longest:</td>
                            <td className="words">
                              {track.longestWords === ""
                                ? "-"
                                : track.longestWords.replaceAll(",", ", ")}
                            </td>
                          </tr>
                          <tr>
                            <td className="caption">Difficult:</td>
                            <td className="words">
                              {track.difficultWords === ""
                                ? "-"
                                : track.difficultWords.replaceAll(",", ", ")}
                            </td>
                          </tr>
                          <tr>
                            <td className="caption">Most Syllables:</td>
                            <td className="words">
                              {track.mostSyllableWords === ""
                                ? "-"
                                : track.mostSyllableWords.replaceAll(",", ", ")}
                            </td>
                          </tr>
                          <tr>
                            <td className="caption">Offensive:</td>
                            <td className="words">
                              {track.offensiveWords === ""
                                ? "-"
                                : track.offensiveWords.replaceAll(",", ", ")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default Analyzer;
