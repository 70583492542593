import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Progress from "./progress";
import { useParams } from "react-router-dom";
import { isInt } from "../helpers";
import { ColorLogo } from "./ColorLogo.styled";

export default function SearchResults() {
  const [search_results, setSearchResults] = useState({});
  const [show_progress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState({ message: "", percent: -1 });
  const [error_message, setErrorMessage] = useState("");
  const [no_results, setNoResults] = useState(false);

  // Get the search terms and page from the url parameters
  let { terms, page } = useParams("/search/:terms/:page");

  // Make sure terms is a string
  if (typeof terms === "undefined") {
    terms = "";
  }

  // Make sure page is a number
  if (typeof page === "undefined") {
    page = 1;
  } else if (!isInt(page)) {
    page = 1;
  }

  // Runs on change of terms, which is tied to the url
  useEffect(() => {
    terms = terms.replaceAll("-", " ");

    // Start search on the page if a query has been set
    if (terms.length > 0) {
      // Clear the search results
      setSearchResults({});

      // Reset all values to initial
      setShowProgress(true);

      if (terms !== "") {
        performSearch(terms, process.env.REACT_APP_SEARCH_COUNT, page);
      } else {
        // Hide the progress info
        setShowProgress(false);
      }
    }
  }, [terms, page]);

  // Performs the search
  const performSearch = async (terms, count, page) => {
    setNoResults(false);

    // Update the progress
    setProgress((prev) => ({
      ...prev,
      message: "Searching albums...",
    }));

    // Create the request
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "terms=" + terms + "&count=" + count + "&page=" + page,
    };

    // Get the search results
    const results = await fetch(
      process.env.REACT_APP_BACKEND_ROOT + "/api/search-albums",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          setShowProgress(false);
          setErrorMessage(
            "Oh no! Something happened during search. Please try again."
          );
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        // Hide the progress info
        setShowProgress(false);

        // Set the results in the state
        setSearchResults(data);

        if (
          data == null ||
          data.results.length < 1 ||
          data.results.albummatches == null ||
          data.results.albummatches.length < 1
        ) {
          console.log("no results");
          setNoResults(true);
        }
      })
      .catch((error) => {
        // Hide the progress info
        setShowProgress(false);

        setErrorMessage(
          "Uh oh. An error occurred on the server. Please try again later."
        );
        console.log("error", error);
      });

    document.title = "Search results for " + terms;
  };

  return (
    <div className="search-results">
      <br />
      {error_message !== "" ? (
        <div className="error">{error_message}</div>
      ) : null}
      {show_progress ? <Progress progress={progress} /> : null}
      {error_message === "" ? (
        <ul>
          {search_results != null &&
          search_results.results != null &&
          search_results.results.length > 0 ? (
            search_results.results.map((item, idx) => {
              return (
                <li key={idx}>
                  <div className="result">
                    <a
                      href={
                        "/analyze/" +
                        encodeURIComponent(item.artist) +
                        "/" +
                        encodeURIComponent(item.name)
                      }
                    >
                      <div className="cover">
                        <img src={item.image_url} alt={item.name} />
                      </div>
                      <div className="details">
                        <div className="album">{item.name}</div>
                        {item.artist ? (
                          <div className="artist">{item.artist}</div>
                        ) : null}
                      </div>
                    </a>
                    <div className="analyze">
                      <Link
                        tabIndex={-1}
                        to={
                          "/analyze/" +
                          encodeURIComponent(item.artist) +
                          "/" +
                          encodeURIComponent(item.name)
                        }
                        className="btn"
                      >
                        <ColorLogo />
                        <span>Analyze</span>
                      </Link>
                    </div>
                  </div>
                </li>
              );
            })
          ) : no_results ? (
            <li className="none">No results were found</li>
          ) : null}
        </ul>
      ) : null}

      {search_results != null && search_results.results != null ? (
        <div className="pagination">
          {parseInt(search_results["page"]) > 1 ? (
            <Link
              to={"/search/" + terms + "/" + (parseInt(page) - 1)}
              className="btn"
            >
              &#8249; Previous page
            </Link>
          ) : null}
          {parseInt(search_results["total"]) >=
          parseInt(search_results["page"]) *
            parseInt(process.env.REACT_APP_SEARCH_COUNT) +
            parseInt(process.env.REACT_APP_SEARCH_COUNT) ? (
            <Link
              to={"/search/" + terms + "/" + (parseInt(page) + 1)}
              className="btn"
            >
              Next page &#8250;
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
