import styled from "styled-components";
import Logo from "./Logo";

const ColorLogo = styled(Logo)`
  .ls-logo svg rect:first-child {
    fill: #f47560;
  }
  .ls-logo svg rect:nth-child(2) {
    fill: #f1e05b;
  }
  .ls-logo svg rect:nth-child(3) {
    fill: #ec008c;
  }
  .ls-logo svg rect:nth-child(4) {
    fill: #ec008c;
  }
  .ls-logo svg rect:nth-child(5) {
    fill: #62cdbb;
  }
  .ls-text {
    font-family: "Audiowide";
  }
`;

export { ColorLogo };
