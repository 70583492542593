import React from "react";
import { FooterLogo } from "./FooterLogo.styled";

const Footer = (props) => {
  return (
    <footer>
      {/*
      <div className="copyright">
        &copy; {new Date().getFullYear()} LyricStat.com
      </div>
      */}
      <div className="logo">
        <FooterLogo showText="true" fontSize="0.9em" />
      </div>
      {/*
      <ul className="credit">
        <li>
          <a
            href="https://www.freepik.com/vectors/hexagon-texture"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hexagon texture vector created by almumtazza - www.freepik.com
          </a>
        </li>
        <li>
          <a
            href="https://www.freepik.com/vectors/background-style"
            target="_blank"
            rel="noopener noreferrer"
          >
            Background style vector created by freepik - www.freepik.com
          </a>
        </li>
        <li>
          <a
            href="https://www.flaticon.com/free-icons/moon"
            title="moon icons"
            target="_blank"
            rel="noopener noreferrer"
          >
            Moon icons created by Freepik - Flaticon
          </a>
        </li>
        <li>
          <a
            href="https://www.flaticon.com/free-icons/sun"
            title="sun icons"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sun icons created by bqlqn - Flaticon
          </a>
        </li>
      </ul>
      */}
    </footer>
  );
};

export default Footer;
