import React, { useState, useEffect } from "react";
import { BarChartHomePage } from "./charts/BarChartHomePage";

const HomePage = () => {
  const [homepageData, setHomepageData] = useState([]);

  useEffect(() => {
    const data = [
      {
        text: "Welcome",
        number: 10,
        "number-color": "#F47560",
      },
      {
        text: "to",
        number: 5,
        "number-color": "#F1E05B",
      },
      {
        text: "Lyric",
        number: 15,
        "number-color": "#EC008C",
      },
      {
        text: "Stat",
        number: 15,
        "number-color": "#EC008C",
      },
      {
        text: ".com",
        number: 8,
        "number-color": "#62CDBB",
      },
    ];

    setHomepageData(data);
  }, []);

  return (
    <div className="home">
      <BarChartHomePage data={homepageData} />
      <p>Your go-to place for analyzing your favorite songs.</p>
    </div>
  );
};

export default HomePage;
