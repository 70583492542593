import styled from "styled-components";
import Logo from "./Logo";

const FooterLogo = styled(Logo)`
  & .ls-logo {
    display: inline-block;
  }
  & .ls-logo svg rect {
    fill: ${({ theme }) => theme.footerLogoColor};
  }
  & .ls-text {
    display: inline-block;
    margin-left: 8px;
    font-weight: bold;
    font-size: ${(props) => props.fontSize || "1em"};
    font-family: "Audiowide";
  }
`;

export { FooterLogo };
