export const lightTheme = {
  backgroundColor: "#fff", 
  backgroundUrl: "",
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
  backgroundSize: "1000px auto",
  color: "#363537",
  toggleBorder: "#fff",
  toggleBackground: "#fff", 
  toggleBackgroundHover: "#e2e2e2", 
  headerBackgroundColor: "#fff",
  headerBackgroundUrl: "/static/images/white-hex.jpg",
  headerBackGroundRepeat: "repeat-x",
  headerBackgroundPosition: "bottom center",
  headerBackgroundSize: "800px auto",
  headerBorderColor: "#fff",
  headerTextColor: "#EC008C",
  headerHightlightColor: "#282c34",
  footerBackgroundColor: "#fff",
  footerBackgroundUrl: "/static/images/white-hex.jpg",
  footerBackGroundRepeat: "repeat-x",
  footerBackgroundPosition: "top center",
  footerBackgroundSize: "1000px auto",
  footerColor: "#282c34",
  footerBorderColor: "#fff",
  footerLogoColor: "#000",
  linkColor: "#282c34",
  searchBorderColor: "#282c34",
  searchTextColor: "#282c34",
  searchLabelColor: "#282c34",
  searchActiveBorderColor: "#EC008C",
  searchIconColor: "#282c34",
  helpIconColor: "#282c34",
  progressBackgroundColor: "#fff",
  progressBorder: "1px solid #eee",
  progressTextColor: "#282c34",
  searchResultsHoverBGColor: "rgba(29, 30, 37, 0.2)",
  dividerColor: "#EC008C",
}
  
export const darkTheme = {
  backgroundColor: "#282c34", 
  backgroundUrl: "",
  backgroundRepeat: "repeat",
  backgroundPosition: "center center",
  backgroundSize: "1000px auto",
  color: "#fff",
  toggleBorder: "#e2e2e2",
  toggleBackground: "#e2e2e2", 
  headerBackgroundUrl: "/static/images/bg-dark-1.jpg",
  headerBackGroundRepeat: "repeat-x",
  headerBackgroundPosition: "center center",
  headerBackgroundSize: "800px auto",
  toggleBackgroundHover: "#fff", 
  headerBackgroundColor: "#1d1e25",
  headerBorderColor: "#EC008C",
  headerTextColor: "#fff",
  headerHightlightColor: "#EC008C",
  footerBackgroundUrl: "/static/images/bg-dark-1.jpg",
  footerBackGroundRepeat: "repeat-x",
  footerBackgroundPosition: "center center",
  footerBackgroundSize: "1000px auto",
  footerBackgroundColor: "#1d1e25",
  footerColor: "#fff",
  footerBorderColor: "#EC008C",
  footerLogoColor: "#fff",
  linkColor: "#fff",
  searchBorderColor: "#fff",
  searchTextColor: "#fff",
  searchLabelColor: "#fff",
  searchActiveBorderColor: "#EC008C",
  searchIconColor: "#fff",
  helpIconColor: "#fff",
  progressBackgroundColor: "#1d1e25",
  progressBorder: "none",
  progressTextColor: "#fff",
  searchResultsHoverBGColor: "rgba(255, 255, 255, 0.2)",
  dividerColor: "#EC008C",
}
