import React from "react";
import { func, string } from "prop-types";
import moon from "../icons/moon.png";
import sun from "../icons/sun.png";
import { ToggleContainer } from "./ToggleContainer";

const ToggleMode = ({ theme, toggleTheme }) => {
  const isLight = theme === "light";

  return (
    <ToggleContainer onClick={toggleTheme} isLight={isLight}>
      <img src={sun} alt="Use dark mode" />
      <img src={moon} alt="Use light mode" />
    </ToggleContainer>
  );
};

ToggleMode.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};

export default ToggleMode;
