import React from "react";
import Search from "./search";
import { func, string } from "prop-types";
import ToggleMode from "./toggleMode";
import { ColorLogo } from "./ColorLogo.styled";

const Header = ({ theme, toggleTheme }) => {
  return (
    <header>
      <div className="logo">
        <a href="/">
          <div className="svg">
            <ColorLogo />
          </div>
          <h1>LyricStat</h1>
        </a>
      </div>
      <Search />
      <div className="mode">
        <ToggleMode theme={theme} toggleTheme={toggleTheme} />
      </div>
    </header>
  );
};

Header.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};

export default Header;
