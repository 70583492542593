import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function Progress(props) {
  const [progress, setProgress] = useState(props.progress);

  useEffect(() => {
    setProgress(props.progress);
  }, [props.progress]);

  return (
    <div className="progress">
      <div className="message">{progress.message}</div>
      {progress.song ? <div className="song">{progress.song}</div> : null}
      <Box className="pb" sx={{ color: "#EC008C" }}>
        {progress.percent >= 0 ? (
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={progress.percent}
          />
        ) : null}

        {progress.percent < 0 ? <LinearProgress color="inherit" /> : null}
      </Box>
    </div>
  );
}
