import React from "react";
import { ResponsiveBar } from "@nivo/bar";

export const BarChartWords = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["Non-unique", "Unique"]}
      indexBy="song"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={({ id, data }) => data[`${id}-color`]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: "#EC008C",
            },
          },
          legend: {
            text: {
              fontWeight: "bold",
              fill: "#EC008C",
            },
          },
        },
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#333333",
            fontSize: 14,
          },
        },
      }}
      defs={[
        {
          id: "lines",
          type: "patternLines",
          background: "#F1E15B",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "Unique",
          },
          id: "lines",
        },
      ]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: (v) => {
          return v.length > 10 ? (
            <tspan>
              {v.substring(0, 10) + "..."}
              <title>{v}</title>
            </tspan>
          ) : (
            v
          );
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -20,
        legend: "Song",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Words",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 4.0]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          itemTextColor: "#EC008C",
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      isFocusable={true}
      ariaLabel="Bar chart for Total Vs Unique Words by Song"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " for song: " + e.indexValue;
      }}
    />
  );
};
